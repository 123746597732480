.group-info-popup {
    max-width: 400px;
    min-width: 400px;

    & .leaflet-popup-content {
        width: 100% !important;
        margin: 0px !important;
    }

    & .group-info-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 16px;

        & input, button {
            margin-bottom: 8px;
        }

        & .form-label {
            margin-bottom: 2px;
        }
    }
}

.gap-group-popup {
    max-width: 400px;
    min-width: 400px;
    z-index: 1000 !important;

    & .leaflet-popup-content {
        width: 100% !important;
        margin: 0px !important;
    }
}

.gap-group-container {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    padding: 16px;

    & input, button {
        margin-bottom: 8px;
    }

    & .form-label {
        margin-bottom: 2px;
    }

    & .gap-name {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;

    }
}

.gap-group-entry {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    border-bottom: 1px solid #e0e0e0;

    & .gap-group-entry-name {
        font-size: 16px;
        font-weight: 500;
    }

    & .gap-group-entry-actions {
        display: flex;
        flex-direction: row;
        gap: 8px;

        & svg {
            cursor: pointer;
        }
    }

    & button {
        margin-top: 16px;
    }
}
