.overlay-image-url-popup {
    max-width: 400px;
    min-width: 400px;
    padding: 16px;

    & .leaflet-popup-content {
        width: 100% !important;
        margin: 0px !important;
    }

    & .overlay-controls {
        margin-top: 16px;
        width: 100%;
        display: flex;
        flex-direction: column;

        & button {
            margin-bottom: 8px;
        }

        & .form-label {
            margin-bottom: 2px;
        }
    }
}
