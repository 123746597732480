.map-container-div-groups {
    //accomodate leaflet map here
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 0;
    .leaflet-container {
        top: 120px;
        height: calc(100% - 120px);
        width: 100%;
        position: absolute;
        z-index: 0;
    }
    .leaflet-control-container {
        z-index: 1;
    }
    .leaflet-control-zoom {
        z-index: 1;
    }
    .leaflet-control-attribution {
        z-index: 1;
    }

    .group-control-checks {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 4px;
        width: 100%;
        gap: 16px;
    }
}
