.map-container-div-overlays {
    //accomodate leaflet map here
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 0;
    .leaflet-container {
        top: 60px;
        height: calc(100% - 60px);
        width: 100%;
        position: absolute;
        z-index: 0;
    }
    .leaflet-control-container {
        z-index: 1;
    }
    .leaflet-control-zoom {
        z-index: 1;
    }
    .leaflet-control-attribution {
        z-index: 1;
    }
}
