
.sidebar-container {
    display: inline-block !important;

    & .sidebar {
        min-height: 100vh;
    }
}

.main-page {
    display: flex;
    flex: 1;
    min-height: 100vh;
    vertical-align: top;
    max-height: 100vh;
    overflow-y: auto;
    padding: 16px;
}

.blocked-streets-form, .alert-rules-form, .alert-rules-form > span  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;

    & h6 {
        margin-top: 20px;
    }
}

.form-submit-section {
    display: flex;
    justify-content: flex-end;

    & button {
        margin-left: 8px;
    }
}

.date-picker {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;

    & label {
        min-width: 120px;
    }
}

.parking-rule-vehicles-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 8px;
}

.parking-rules-container {
    width: 100%;

    & .spinner {
        padding: 16px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.table-button {
    min-width: 48px;
}


.login-form {
    max-width: 400px;
    margin: 120px auto;
    max-height: 400px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
}

.sidebar-brand img {
    width: 100%;
    height: 100%;
    padding: 16px;

    object-fit: contain;
}

.sidebar-brand .round-icon {
    border-radius: 50%;
    max-height: 72px;
}

.nav-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.parking-rule-time-slots, .blocked-streets-time-slots {
    display: flex;
    flex-direction: row;
    gap: 16px;

    & .all-day-checkbox {
        min-width: 75px;
        padding-top: 6px;
    }
}

.add-button {
    max-width: 100px;
}

.file-upload-container .file-upload {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 8px;
}

.file-upload-container > label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.image-view > img{
    max-width: 160px;
    min-width: 160px;
    max-height: 80px;
    min-height: 80px;
    object-fit: scale-down;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
}

.file-upload {
    & .form-control {
        align-self: flex-start;
    }
}

.root-container {
    display: flex;
    flex-direction: row;
}

.main-page {
    & > div {
        width: 100%;
    }
}

.login-container {
    width: 100%
}

.coordinate-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    width: 100%;

    & .position {
        flex: 1;
    }

    & .textboxes {
        flex: 15;
        display: flex;
        flex-direction: row;
        gap: 16px;
    }
}

.spinner {
    display: inline-block;
    vertical-align: middle;
    margin-left: 16px;
}

.rules-row {
    border-bottom: 1px solid #e0e0e0;
}

.parking-rules-cell, .parking-timeslots-cell {
    & span {
        display: block;
        padding: 0px 0px !important;
    }
}

.parking-icons-cell {
    & .image-view > img {
        max-width: 50px;
        min-width: 50px;
        max-height: 25px;
        min-height: 25px;
    }

    & .image-view {
        padding: 2px !important;
    }
}

.parking-icons-gaps {
    & > * {
        display: inline;
        padding: 0px 2px !important;
    }
}

.parking-rule-vehicles-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & textarea {
        height: calc(100vh - 150px);
    }
}

.parking-rule-vehicles-row {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.parking-rule-vehicles-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;

    & button {
        flex: 1;
    }
}

.stats-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & .stats-form {
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: space-between;

        & input, select {
            flex: 2;
        }

        & button {
            flex: 1;
        }
    }

    & .stats-tweaks {
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: space-between;
        align-items: center;

        & input {
            flex: 3;
        }

        & .form-check {
            flex: 1;
        }
    }
}

.ors-test-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & .ors-test-result {
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: space-between;
        align-items: flex-start;
        flex: 1;

        & .ors-test-history {
            flex: 2;
            padding-right: 16px;
            border-right: 1px solid #e0e0e0;
            min-height: 100%;
            max-height: calc(100vh - 300px);
            align-items: flex-start;
            overflow-y: auto;
            overflow-x: hidden;

            & button {
                width: 100%;
                margin-bottom: 16px;
            }

            & .ors-test-history-item {
                display: flex;
                flex-direction: row;
                gap: 16px;
                padding: 4px;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #e0e0e0;

                & .ors-test-history-item-index {
                    flex: 1;
                }

                & .ors-test-history-item-date {
                    flex: 5;
                }

                & .ors-test-history-red {
                    color: #f44336;
                }

                & .ors-test-history-green {
                    color: #4caf50;
                }

                &:hover {
                    cursor: pointer;
                    box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
                }
            }

        }

        & .ors-test-result-details {
            flex: 5;
            max-height: calc(100vh - 300px);
            overflow-y: auto;
        }
    }
}

.alerts-container {
    & .alert-resolved {
        text-decoration: line-through;
    }

    & .alert-unresolved {
        text-decoration: none;
        background: #ff8077;
    }

    & tr {
        height: 40px;
    }
}

table {
    display: block;
    overflow-y: auto;
    max-height: calc(100vh - 80px);

    & td {
        padding: 0px 16px !important;
    }
}


.users-container {
    & tr {
        height: 40px;

        & .permissions-container {
            display: flex;
            flex-direction: column;
            gap: 2px;
        }
    }
}
