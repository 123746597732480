.search-select-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .clear-icon {
        cursor: pointer;
        margin-left: 5px;
    }

    .search-list {
        position: absolute;
        z-index: 1;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        width: 100%;
        max-height: 400px;
        overflow-y: auto;
        margin-top: 5px;
        padding: 0;
        list-style: none;
        margin: 0;
        padding: 0;

        ul {
            list-style-type: none;
            padding: 0;
            margin-bottom: 0;
        }

        li {
            font-size: medium;
            cursor: pointer;
            padding: 8px 16px;
            &:hover, &.highlighted {
                background-color: #c5c5c5;
            }
        }
    }
}
