.timeline {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
}

.timeline-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    & input[type="number"] {
        max-width: 76px;
    }

    & label {
        min-width: 160px;
        text-align: center;
        margin-bottom: 0px;
    }
}

.timeline-controls {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    align-items: center;
}
