.map-container-div {
    //accomodate leaflet map here
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 0;
    .leaflet-container {
        top: 300px;
        height: calc(100% - 300px);
        width: 100%;
        position: absolute;
        z-index: 0;
    }
    .leaflet-control-container {
        z-index: 1;
    }
    .leaflet-control-zoom {
        z-index: 1;
    }
    .leaflet-control-attribution {
        z-index: 1;
    }

    .vehicle-info-popup {
        max-width: 400px;
        min-width: 400px;

        & .leaflet-popup-content {
            width: 100% !important;
            margin: 0px !important;
        }

        & .vehicle-info-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 16px;

            & input {
                margin-bottom: 8px;
            }

            & .form-label {
                margin-bottom: 2px;
            }
        }
    }
}

.add-free-button {
    background-image: url("https://png.pngtree.com/png-vector/20190214/ourmid/pngtree-vector-plus-icon-png-image_515260.jpg") !important;
}

.add-car-button {
    background-image: url("https://png.pngtree.com/element_our/sm/20180516/sm_5afbf1d28feb1.jpg") !important;
}

.add-truck-button {
    background-image: url("https://png.pngtree.com/png-vector/20190629/ourmid/pngtree-truck-icon-png-image_1530300.jpg") !important;
}

.delete-button {
    background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRY45xvveSnvs87isWARiPau5o9zxvzsXMu0XK_tceXA-VCF_Qv641Brg2yDGoc-x6agNg&usqp=CAU") !important;
}

.clear-button {
    background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJ0FylmfgOSLqUO-_kjcNIDZ1VwRMD3MIE-8uA3GUFNw&s") !important;
}

.controls {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 8px;

    & h3 {
        margin-bottom: 0px;
    }

    & > div {
        min-width: 450px;
    }
}

.json-modal-body pre {
    max-height: calc(100vh - 240px);
}

.controls-buttons {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    margin-bottom: 8px;
}

.popup-actions {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    padding: 16px;
}

.history-controls {
    max-width: 500px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: flex-end;

    & .look-back-minutes {
        max-width: 100px;
    }
}
